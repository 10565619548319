import React from 'react'

// import Menu from '../../components/Menu'
// import MenuTable from '../../components/MenuTable'

// import { menuData } from '../../assets/menuData.js'

class SpecialsPage extends React.Component
{
	render()
	{
		/* Disabled for now
		// Our specials are built from our regular menu items
		const specials = menuData.specials.map((special) =>
		{
			// If we are requesting location-specific menu items, check for that here
			if (this.props.location)
			{
				// If we got here, our current location is NOT selected for this menu item! Skip it!
				if (special.locations != null && !special.locations.includes(this.props.location))
					return null;
			}

			return (<React.Fragment key={special.name}>
				<h3>{special.name} - ${special.price}</h3>
				<p>{special.description}</p>
			</React.Fragment>
			);
		});

		return (
			<div id="specialsmenu" className="wrapper style1">
				<div className="container">
					<Menu>
						<header className="major">
							<h2>Current Specials and Promotions</h2>
						</header>
						<div className="box alt">
							<div className="row">
								<div className="col-8 col-12-xsmall">
									{specials}
								</div>
							</div>
						</div>
					</Menu>
				</div>
			</div>
		)
		*/

		return null;
	}
}

export default SpecialsPage